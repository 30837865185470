<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver
      v-slot="{ invalid }"
      tag="div"
      ref="observer"
      class="private_viewing_room"
      id="scroll-block"
    >
      <header class="private_viewing_room__header">
        <h3 class="private_viewing_room__header_title">
          {{ private_viewing_room.id ? "Edit" : "Add" }} Room
        </h3>
        <div class="private_viewing_room__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete Room"
            @click="$emit('removePrivateViewingRoom', private_viewing_room)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="
              invalid ||
              private_viewing_room.search_image === '' ||
              (private_viewing_room.featured && private_viewing_room.home_page_image === '') ||
              !changed
            "
            @click="savePrivateViewingRoom"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <h3 class="form__header">Viewing Room Information</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Room Name"
              :required="true"
              :validateError="errors[0] || serverError.title"
              v-model="private_viewing_room.title"
              :element="private_viewing_room.title"
              @input="private_viewing_room.title = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, path: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0] || serverError.slug"
              v-model="private_viewing_room.slug"
              :element="private_viewing_room.slug"
              @input="private_viewing_room.slug = $event"
            >
              <template #text>
                <span class="form-control__simple-text">
                  Example:
                  {{ `${url}/private-viewing-room/${private_viewing_room.slug || "path"}` }}
                </span>
              </template>
            </BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row form__row--mb-17 form__row--align-start">
          <div class="form__row_col form__row_col--side form__row_col--flex">
            <SimpleToggle
              placeholder="Page Active"
              v-model="private_viewing_room.status"
              :disabled="invalid"
              :selected="+private_viewing_room.status"
              @input="private_viewing_room.status = Number($event)"
            ></SimpleToggle>
          </div>
        </div>
      </section>
      <section v-for="item in private_viewing_room.sections" class="form">
        <h3 class="form__header">
          Section
          <IconButton
            class="button--admin button--auto-xs button--outline-grey button--ml-15"
            text="remove ARTWORK"
            @click="removeSection(item)"
          >
            <IconPlusBold></IconPlusBold>
          </IconButton>
        </h3>
        <div class="form__row">
          <div class="form__row_col form__row_col--side">
            <BaseInput
              placeholder="Artwork Title"
              :required="true"
              v-model="item.title"
              :element="item.title"
              :disabled="true"
            >
            </BaseInput>
          </div>
          <div class="form__row_col form__row_col--side">
            <BaseInput
              placeholder="Artist"
              :required="true"
              v-model="item.artist"
              :element="item.artist"
              :disabled="true"
            >
            </BaseInput>
          </div>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ max: 65535 }"
            v-slot="{ errors }"
          >
            <BaseTextarea
              placeholder="Description"
              :validateError="errors[0]"
              v-model="item.description"
              :element="item.description"
              @input="item.description = $event"
            ></BaseTextarea>
          </ValidationProvider>
        </div>
        <div
          class="form__row form__row--bordered form__row--justify-start"
          style="padding: 8px; align-items: flex-start"
        >
          <span class="form__row_label">Image Carousel</span>
          <div v-for="(i, idx) in item.carousel" :key="`image-${idx}`" style="margin: 4px">
            <DragAndDropImage
              :id="`${item.title}-${idx}`"
              :image="i.url"
              size="15"
              @input="item.carousel.splice(idx, 1, { url: $event.image, caption: i.caption })"
              @removeImage="item.carousel.splice(idx, 1)"
            ></DragAndDropImage>
            <BaseInput
              class="form-control--xs"
              style="margin-top: 20px"
              placeholder="Caption"
              v-model="i.caption"
              :element="i.caption"
              @input="i.caption = $event"
            ></BaseInput>
          </div>
          <DragAndDropImage
            :id="`${item.title}`"
            size="15"
            @input="item.carousel.push({ url: $event.image, caption: '' })"
            style="margin: 4px"
          ></DragAndDropImage>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Add New Artwork Section</h3>
        <div class="form__row">
          <MultipleAutocompleteInput
            id="artwork-section"
            placeholder="Artwork"
            :focusOpen="true"
            :loading="artworkLoading"
            :matches="artworks"
            :creating="false"
            :simple="true"
            @selectMatch="private_viewing_room.sections.push($event)"
            @searchMatches="apiGetSearchedArt($event)"
          ></MultipleAutocompleteInput>
        </div>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import BaseTextarea from "@/components/inputs/BaseTextarea.vue";
import DragAndDropImage from "@/components/DragAndDropImage.vue";
import Editor from "@/components/inputs/Editor.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Toggle from "@/components/inputs/Toggle.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import SimpleToggle from "@/components/inputs/SimpleToggle.vue";
import AutocompleteInput from "@/components/inputs/AutocompleteInput.vue";
import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";

import IconButton from "@/components/buttons/IconButton.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconClose from "@/components/icons/IconClose.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    BaseInput,
    BaseTextarea,
    BaseSelect,
    DragAndDropImage,
    Editor,
    Toggle,
    DatePicker,
    IconButton,
    IconPlus,
    IconClose,
    IconPlusBold,
    SimpleToggle,
    AutocompleteInput,
    MultipleAutocompleteInput,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      private_viewing_room: {
        title: "",
        slug: "",
        status: 0,
        sections: [],
      },
      changed: false,
      artTitle: "",
    };
  },
  created() {
    if (this.item != null) {
      this.private_viewing_room = JSON.parse(JSON.stringify(this.item));
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("privateViewingRoom", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
    ...mapGetters("artworks", {
      artworks: "getSearchedArtworks",
      artworkLoading: "getChangesLoading",
    }),
  },
  watch: {
    private_viewing_room: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (this.$refs.observer.flags.dirty) {
            this.changed = true;
          }
          if (!val.status) {
            this.private_viewing_room.featured = false;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("artworks", ["apiGetSearchedArt"]),

    ...mapMutations("privateViewingRoom", ["setError"]),

    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid &&
            this.private_viewing_room.title != "" &&
            this.private_viewing_room.slug != ""
              ? this.savePrivateViewingRoom()
              : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    removeSection(section) {
      const sections = this.private_viewing_room.sections.filter(el => {
        return section != el;
      });
      this.private_viewing_room.sections = sections;
    },
    savePrivateViewingRoom() {
      let data = {
        ...this.private_viewing_room,
      };
      if (this.private_viewing_room.id) {
        this.$emit("updatePrivateViewingRoom", { data });
      } else {
        this.$emit("createPrivateViewingRoom", { data });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.private_viewing_room {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      color: $black;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
  &__sponsor-button {
    width: max-content;
    min-width: 3.4rem;
    padding: 0 10px;
  }
}
</style>

